import React, { useState } from "react";
import { Container, Box, Typography, Divider, Button } from "@mui/material";
import Layout from "../components/base-structure/Layout";
import BlogBox from "../components/common/BlogBox";
import LI from "../images/LinkedIn.svg";
import FB from "../images/FB.svg";
import TW from "../images/TwitterWriter.svg";
import { BsDot } from "react-icons/bs";
import { graphql, Link } from "gatsby";
import Customizable from "../components/home/Customizable";
import "../styles/WriterProfile.scss";
import Seo from "../components/common/SEO";

const WriterProfile = ({ data }) => {
  const [visible, setVisible] = useState(3);

  const readmore = () => {
    setVisible((visible) => visible + 3);
  };
  const showless = () => {
    setVisible(3);
  };

  return (
    <Layout>
      <Seo
        title={data?.strapiAuthorProfile?.metaTitle}
        description={data?.strapiAuthorProfile?.metaDescription}
        tag="taged"
        pageUrl={data?.strapiAuthorProfile?.Add_Canonical_Link}
      />
      <Container>
        <Box className="writerProfileWrapper">
          <Box className="writerProfileImage">
            <img src={data?.strapiAuthorProfile?.ProfilePic[0]?.url} alt="" />
          </Box>
          <Box className="writerProfileContent">
            <Box className="writerProfileText">
              <Typography variant="h1">
                {data?.strapiAuthorProfile?.Name}
              </Typography>
              <BsDot className="writerProfileSeparater" />
              <Typography className="writerProfileRole">
                {data?.strapiAuthorProfile?.Designation}
              </Typography>
            </Box>
            <Box className="writerProfileDescription">
              <Typography variant="h6">
                {data?.strapiAuthorProfile?.Body}
              </Typography>
              <Box className="writerProfileDivider"></Box>
            </Box>
            <Box className="writerSocialLinks">
              <Link to={data?.strapiAuthorProfile?.LinkedIn} target="_blank">
                <img src={LI} alt="" />
              </Link>
              <Link to={data?.strapiAuthorProfile?.Facebook} target="_blank">
                <img src={FB} alt="" />
              </Link>
              <Link to={data?.strapiAuthorProfile?.Twitter} target="_blank">
                <img src={TW} alt="" />
              </Link>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box className="writerBlogs">
          <Box className="moreFromWriter">
            <Typography variant="h2">
              More from {data?.strapiAuthorProfile?.Name}
            </Typography>
          </Box>
          <Box className="writerBlogFlex">
            {data?.allStrapiBlog?.nodes &&
              data?.allStrapiBlog?.nodes
                ?.slice(0, visible)
                ?.map((blog, index) => {
                  return <BlogBox blog={blog} key={index} indexProp={true} />;
                })}
          </Box>
          {data.allStrapiBlog.nodes.length > 3 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "20px 0px",
              }}
            >
              {visible >= data.allStrapiBlog.nodes.length ? (
                <Button
                  onClick={showless}
                  sx={{
                    backgroundColor: "orange",
                    color: "white",
                    padding: "10px 20px",
                    borderRadius: "50px",
                    fontFamily: "Poppins",
                    "&:hover": {
                      backgroundColor: "orange",
                      color: "white",
                    },
                  }}
                >
                  Show Less
                </Button>
              ) : (
                <Button
                  onClick={readmore}
                  sx={{
                    backgroundColor: "#611F69",
                    color: "white",
                    padding: "10px 20px",
                    borderRadius: "50px",
                    fontFamily: "Poppins",
                    "&:hover": {
                      backgroundColor: "#611F69",
                      color: "white",
                    },
                  }}
                >
                  Load More
                </Button>
              )}
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Container>
      <Customizable
        indexprop={5}
        title="Ready to sign up for an extremely
customizable experience?"
        background="#FBF7EE"
        textColor="#611F69"
        pointsColor="#611F69"
      />
      {/* <span
        dangerouslySetInnerHTML={{
          __html: data?.strapiAuthorProfile?.Add_Schema,
        }}
      /> */}
    </Layout>
  );
};

export default WriterProfile;

export const query = graphql`
  query MyQuery($Slug: String) {
    allStrapiBlog(
      filter: { author_profile: { Slug: { eq: $Slug } } }
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        Slug
        Content
        Title
        author_profile {
          Slug
          Name
        }
        id
        Image {
          url
        }
        categories {
          Slug
          Name
          Slug
        }
        createdAt
        check {
          data {
            check
          }
        }
      }
    }
    strapiAuthorProfile(Slug: { eq: $Slug }) {
      Add_Canonical_Link
      Body

      Designation
      Name
      Facebook
      LinkedIn
      Twitter
      ProfilePic {
        url
      }
      Slug
      metaDescription
      metaTitle
    }
  }
`;
